import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from './utils/store';
import App from './components/App';
import { BASENAME } from './const';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={BASENAME}>
    <StoreProvider>
      <App />
    </StoreProvider>
  </BrowserRouter>
);
