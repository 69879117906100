import React, { useEffect } from 'react';
import Modal from '../Modal';

import './Legal.scss';

const Legal = ({ onClose }) => {
  useEffect(() => {
    const handleEscapeKey = event => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  return (
    <Modal className="legal" onClose={onClose}>
      <h3>Terms Of Service</h3>
      <p>
        Any tool or application (collectively “<b>Apps</b>”) provided under Cloudinary Labs Portal
        (“<b>Portal</b>”) is an unsupported pre-production prototype undergoing further development
        and provided on an “AS IS” basis without warranty of any kind, express or implied,
        including, but not limited to, the implied warranties of merchantability and fitness for a
        particular purpose are disclaimed. Furthermore, Cloudinary is not under any obligation to
        provide a commercial version that incorporates all or some of the Apps and may at any time,
        temporarily or permanently, suspend the Portal and Apps, or a portion thereof, at its sole
        discretion.
      </p>
      <p>
        Notwithstanding anything to the contrary under any agreement or written instrument between
        You and Cloudinary, Your use of the Portal and Apps is at your sole risk and Cloudinary will
        not be liable for any direct, indirect, incidental, special, exemplary, consequential or
        similar damages (including, but not limited to, procurement of substitute goods or services;
        loss of use, data, or profits; or business interruption) however caused and on any theory of
        liability, whether in contract, strict liability, or tort (including negligence or
        otherwise) arising in any way out of the use of the Portal and Apps, even if advised of the
        possibility of such damage. All rights to and in the Portal and Apps are and will remain at
        all times, owned by, or licensed to Cloudinary.
      </p>
      <p>
        Your data will be and remain, as between Cloudinary and You, Your sole and exclusive
        property. You provide Cloudinary a license to use Your data for the purpose of providing the
        Portal, including a license to store, record, transmit, transfer to third parties, maintain,
        and display Your data as necessary to provide the Portal and Apps. You should refrain from
        uploading any confidential or personally identifiable information to the Portal and Apps.
      </p>
      <p>
        You must not misuse the Portal and Apps by knowingly introducing viruses, trojans, worms,
        logic bombs or other material which is malicious or technologically harmful. You must not
        attempt to gain unauthorised access to the Portal and Apps, the server on which they are
        stored, or any server, computer or database connected to them. You may not modify, make
        derivative works of, disassemble, de-compile or reverse engineer any part of the Apps code.
      </p>
      <p>
        YOU MAY NOT USE THE PORTAL AND APPS FOR ANY ACTIVITY THAT CONSTITUTES OR ENCOURAGES CONDUCT
        THAT WOULD CONSTITUTE A CRIMINAL OFFENSE, GIVE RISE TO CIVIL LIABILITY OR OTHERWISE VIOLATE
        ANY APPLICABLE LOCAL, STATE, NATIONAL, FOREIGN LAW OR INDUSTRY STANDARD, INCLUDING, WITHOUT
        LIMITATION, ANY APPLICABLE LAWS AND REGULATIONS GOVERNING PRIVACY, MASS EMAIL, SPAM, EXPORT
        CONTROL, CONSUMER PROTECTION, UNFAIR COMPETITION AND FALSE ADVERTISING. ADDITIONALLY, YOU
        MUST NOT USE THE PORTAL AND APPS FOR ANY ACTIVITY THAT WILL BE DEEMED, OR ENCOURAGES
        ACTIVITY THAT WILL BE DEEMED AS THREATENING, HARASSING, ANTI-COMPETITIVE, MISLEADING,
        LIBELOUS, DEFAMATORY, OBSCENE, PORNOGRAPHIC, PROFANE OR OTHERWISE OBJECTIONABLE.
      </p>
    </Modal>
  );
};

export default Legal;
